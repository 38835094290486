<template>
  <Page>
    <div class="home-page">
      <zui-header/>
      <div class="login-box" :style="{ height: height }">
        <img src="https://www.achencybe.com/img/pc/login/5-1.jpg" style="width: 600px"/>
        <div class="form-box">
          <img src="https://www.achencybe.com/img/pc/logo_1.png"/>
          <div class="text-box" style="margin-top: -20px">
            <div class="title">{{ $t('login_page.login_title_1') }}</div>
            <div>{{ $t('login_page.login_title_2') }}</div>
          </div>
          <div class="tabs-box">
            <span @click="active = 0" :class="{ active: active === 0 }"
            >{{ $t('login_page.login_type_phone') }}</span
            >
            <span @click="active = 1" :class="{ active: active === 1 }"
            >{{ $t('login_page.login_type_mail') }}</span
            >
            <span
                class="border-line"
                :style="{ left: active === 0 ? 0 : '50%' }"
            ></span>
          </div>

          <el-form
              ref="phoneForm"
              key="phoneForm"
              :rules="phoneRules"
              :model="phoneForm"
              style="width: 310px"
              v-if="active === 0"
          >
            <el-form-item prop="phone">
              <zui-country-phone :sendSms="false"
                                 :placeholder="$t('register_page.login_phone_placeholder')"
                                 v-model="phoneForm.phone"
                                 v-on:listenToChildEvent="showRealNumber"
              >

              </zui-country-phone>

              <!--              <el-input-->
              <!--                  v-model="phoneForm.phone"-->
              <!--                  :placeholder="$t('login_page.login_phone_placeholder')"-->
              <!--              ></el-input>-->
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  v-model="phoneForm.password"
                  type="password"
                  :placeholder="$t('login_page.login_mail_password_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  style="width: 100%"
                  @click="submitForm('phoneForm')"
              >{{ $t('login_page.login_button') }}
              </el-button
              >
              <br/>

              <div>
                <span style="margin-left: 58px">
                  <el-link
                      type="primary"
                      :underline="false"
                      @click="$router.push({ name: 'RegisteredPage' })"
                  >{{ $t('login_page.sign_up_button') }}</el-link
                  >
                </span>
                <div
                    style="
                    float: right;
                    font-size: 12px;
                    line-height: 43px;
                    cursor: pointer;
                  "
                    @click="$router.push({ name: 'LoginByPhonePage' })"
                >
                  {{ $t('login_page.retrieve_password') }}
                </div>
              </div>
            </el-form-item>
          </el-form>
          <el-form
              ref="emailForm"
              key="emailForm"
              :rules="emailRules"
              :model="emailForm"
              style="width: 310px"
              v-if="active === 1"
          >
            <el-form-item prop="email">
              <el-input
                  v-model="emailForm.email"
                  :placeholder="$t('login_page.login_mail_address_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  v-model="emailForm.password"
                  type="password"
                  :placeholder="$t('login_page.login_mail_password_placeholder')"
              ></el-input>
            </el-form-item>
            <!--            <el-form-item>-->
            <!--              <el-checkbox v-model="phoneForm.subscribe"-->
            <!--                >同意并愿意遵守Achen&Cybe-->
            <!--                <el-link-->
            <!--                  @click.native.stop="$router.push({ name: 'clausePage' })"-->
            <!--                  >使用条款</el-link-->
            <!--                ></el-checkbox-->
            <!--              >-->
            <!--            </el-form-item>-->
            <el-form-item>
              <el-button
                  type="primary"
                  style="width: 100%"
                  @click="submitForm('emailForm')"
              >{{ $t('login_page.login_button') }}
              </el-button
              >
              <br/>
              <div>
                <span style="margin-left: 58px">
                  <el-link
                      type="primary"
                      :underline="false"
                      @click="$router.push({ name: 'RegisteredPage' })"
                  >{{ $t('login_page.sign_up_button') }}</el-link
                  >
                </span>

                <div
                    style="
                    float: right;
                    font-size: 12px;
                    line-height: 43px;
                    cursor: pointer;
                  "
                    @click="$router.push({ name: 'LoginByPhonePage' })"
                >
                  {{ $t('login_page.retrieve_password') }}
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiCountryPhone from "../../../components/CountryPhone";

export default {
  components: {Page, ZuiHeader, ZuiCountryPhone},
  computed: {
    emailRules() {
      return {
        email: [
          {required: true, message: window.vm.$t('login_and_register_page.input_email'), trigger: "blur"},
          {
            pattern: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
            message: window.vm.$t('login_and_register_page.input_email'),
            trigger: "blur",
          },
        ],
        password: [{required: true, message: window.vm.$t('login_and_register_page.input_password'), trigger: "blur"}],
      }
    },
    phoneRules() {
      return {
        phone: [
          {required: true, message: window.vm.$t('login_and_register_page.input_phone'), trigger: "blur"},
          {
            pattern: this.realNumber.startsWith('+86') ? /^1[3-9]\d{9}$/ : /\d+/,
            message: window.vm.$t('login_and_register_page.input_phone_uncheck'),
            trigger: "blur",
          },
        ],
        password: [{required: true, message: window.vm.$t('login_and_register_page.input_password'), trigger: "blur"}],
      }
    },
  },
  data() {
    return {
      realNumber: '',
      height: "",
      active: 0,
      seconds: 0,
      emailForm: {
        email: "",
        password: "",
      },
      phoneForm: {
        phone: "",
        password: "",
        realNumber: ""
      },


    };
  },
  mounted() {
    this.height = window.innerHeight + "px";
  },
  methods: {
    showRealNumber(number) {
      this.realNumber = number
      this.phoneForm.realNumber = number;
      if (number.startsWith('+86')) {
        number = number.substring(3);
      }
      //console.log(this.realNumber)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const Request =
              formName === "emailForm"
                  ? this.$api.loginByEmail
                  : this.$api.loginByPhonePwd;
          Request(this[formName])
              .then((res) => {
                if (res.code === 0) {
                  this.$store.commit("setTicket", res.data.ticket);
                  return this.$api.getUserId({ticket: res.data.ticket});
                }
              })
              .then((res) => {
                if (res.code === 0) {
                  return this.$api.getClientInfo({id: res.data.userId});
                }
              })
              .then((res) => {
                if (res.code === 0) {
                  this.$store.commit("setUserInfo", res.data);
                  this.$message({
                    type: "success",
                    message: window.vm.$t('login_and_register_page.login_success'),
                    onClose: () => {
                      if (window.sessionStorage.getItem("isCompletion")) {
                        let query = JSON.parse(window.sessionStorage.getItem("isCompletion"));
                        this.$router.push({
                          name: "OrderPage",
                          query: query,
                        });
                      } else {
                        this.$router.push("/");
                      }
                    },
                  });
                }
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .form-box {
    width: 500px;
    height: 722px;
    background-color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ::v-deep .el-input-group--append .el-input__inner {
      border-right: 0px !important;
    }

    ::v-deep .el-input-group__append {
      background-color: #fff !important;
      border-left: 0px !important;
    }

    .append {
      position: absolute;
      bottom: 4px;
      border: 0px;
      right: 1px;
      height: 34px;

      &:hover {
        color: #409eff;
        background-color: #fff;
      }
    }

    img {
      width: 240px;
    }

    .text-box {
      width: 280px;
      text-align: center;
      margin: 0 auto;

      .title {
        margin-top: 60px;
        font-size: 30px;
      }

      .sub-title {
        font-size: 12px;
      }
    }

    .tabs-box {
      height: 50px;
      line-height: 50px;
      width: 280px;
      margin: 30px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      position: relative;

      span {
        width: 50%;
        cursor: pointer;

        &.active {
          color: #4aabf2;
        }
      }

      .border-line {
        position: absolute;
        width: 50%;
        bottom: 0;
        height: 2px;
        z-index: 2;
        background-color: #4aabf2;
        transition: left 0.3s;
      }
    }

    .el-form {
      margin: 40px auto 0;
    }
  }
}
</style>